<template>
   <div>Mes offres</div>
</template>

<script>
import  Breadcrumb  from '../components/Breadcrumb.vue'
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Mesoffres",
    mounted() { 
        (() => { Breadcrumb.page = 3} )
    }
    }
</script>