import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import Home from './vues/Home'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faBars, faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft,  faAngleRight} from '@fortawesome/free-solid-svg-icons'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { store } from './store'
import { getUser }  from './common'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Mesdemandes from './vues/Mesdemandes.vue';
import Mesoffres from './vues/Mesoffres.vue';
import Loginpage from './vues/Loginpage.vue'


library.add(faUserSecret, faBars, faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight)
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false
Vue.use(Buefy)
Vue.use(VueRouter);
Vue.use(store);

Vue.component('font-awesome-icon', FontAwesomeIcon)

const routes = [
  { path: '/', component: Home  },
  { path: '/login', component: Loginpage  },
  { path : '/mesdemandes', component: Mesdemandes,

    beforeEnter: async (to, from, next) => {
      let USERID = await getUser();
      if(USERID > 0) {  next(); } else { next('/login')  } 
    }

  },
  { path: '/mesoffres', component: Mesoffres,

    beforeEnter: async (to, from, next) => {
      let USERID = await getUser();
      if(USERID > 0) {  next(); } else { next('/login')  } }
    }
]

const router = new VueRouter({
  routes
})

setInterval(async function() {
  // your code goes here...
  store.commit('setUserId', await getUser()); 
}, 30 * 1000); 

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')