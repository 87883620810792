<template>
<Breadcrumb :page="2"/>
</template>
<script>
import  Breadcrumb  from '../components/Breadcrumb.vue'


export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Mesdemandes",
    components: {
        Breadcrumb
    }
}
</script>