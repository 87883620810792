<!-- eslint-disable vue/valid-v-for -->
<template>
  <div class="hello" id="home">   
    

<section>
<Filterform @filtrer="filtrer" :user="USER_ID" :demandeurs="demandeurs"/> 

<Demandestable :demandes="data" title="Demandes publiées" :totalRecord="total" 
@setParPage="setParPage" :user="USER_ID" @fromdebut="fromDebut" @toprevpage="toPrevPage" @tonextpage="toNextPage" @tolastpage="toLastPage"/>

</section>




  </div>
</template>



<script>
import axios from "axios";
import Filterform from '../components/Filterform.vue';
import Demandestable from '../components/Demandestable.vue';
import { getUser } from "@/common";
import { store } from '@/store';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: {    
  Filterform,
  Demandestable
        },
  props: {
    msg: String
  },

  data() {
            var data = []
            var demandeurs = []
            var filtre = {"ref":"","dated":"", "datef":"", "desc":"", "prop": 0,"parpage": 10, debut:this.debut};
            return {
            filtre,  
            data,   
            demandeurs,                          
            selected: data[1],
            total: 200,
            current: 0,
            perPage: 10,     
            f_ref:"",
            f_dated:"",
            f_datef:"",
            f_desc:"",
            f_prop:0,
            USER_ID : 0,
            debut:0,
            nextrow:0,
            lastdebut:0,
            prevrow:0
            }
        },
    methods: {

      filtrer(f_ref, f_dated, f_datef, f_desc, f_prop){
        this.f_ref = f_ref;
        this.f_dated = f_dated;
        this.f_datef = f_datef;
        this.f_desc = f_desc;
        this.f_prop = f_prop;      
        this.debut = 0;
        this.filtre = {"ref":f_ref,"dated":f_dated, "datef":f_datef, "desc":f_desc, "prop":f_prop,"parpage": parseInt(this.perPage), debut:0 }
        console.log("filtrer : ", JSON.stringify(this.filtre));
        this.getpubdemandes();
      },

setParPage(pp){
  this.perPage = pp;
  console.log('par page : ' , pp, "  |   " , this.perPage);
  this.filtre = {"ref":this.f_ref,"dated":this.f_dated, "datef":this.f_datef, "desc":this.f_desc, 
  "prop": parseInt(this.f_prop),"parpage": parseInt(this.perPage), debut:this.debut}
  this.getpubdemandes();
},



fromDebut()
{
  this.filtre = {"ref":this.f_ref,"dated":this.f_dated, "datef":this.f_datef, "desc":this.f_desc, 
  "prop": parseInt(this.f_prop),"parpage": parseInt(this.perPage), debut:0}
  this.getpubdemandes();
},

toPrevPage()
{
  this.filtre = {"ref":this.f_ref,"dated":this.f_dated, "datef":this.f_datef, "desc":this.f_desc, 
  "prop": parseInt(this.f_prop),"parpage": parseInt(this.perPage), debut:this.prevrow}
  this.getpubdemandes();
},

toNextPage()
{
  this.debut = this.nextrow;
  console.log('nextrow : ' , this.nextrow);
  this.filtre = {"ref":this.f_ref,"dated":this.f_dated, "datef":this.f_datef, "desc":this.f_desc, 
  "prop": parseInt(this.f_prop),"parpage": parseInt(this.perPage), debut:this.nextrow}
  this.getpubdemandes();
},

toLastPage()
{
  this.filtre = {"ref":this.f_ref,"dated":this.f_dated, "datef":this.f_datef, "desc":this.f_desc, 
  "prop": parseInt(this.f_prop),"parpage": parseInt(this.perPage), debut:this.lastdebut}
  this.getpubdemandes();
},

getDemandeurs()
{
  axios.get(store.state.API_URL+'getdemandeurs').then(response => {
    this.demandeurs = response['data'];
  })
},


getUserLangue()
{
  // eslint-disable-next-line no-undef
  axios.get(store.state.API_URL+'getlangue',  { withCredentials:true}).then(response => {
    let langue = response["data"];
    switch(langue) {
      case "ar" : {  this.$store.commit('toAr');  break; }
      case "fr" : {  this.$store.commit('toFr');  break; }
      case "en" : {  this.$store.commit('toEn');  break; }
      default : {  this.$store.commit('toFr');  break; }
    }
  
  });
},


      getpubdemandes()
      {
        


        axios.post(store.state.API_URL+'pubdemandes', this.filtre, { withCredentials:true}
            )
        .then(response => { 
          this.total = response["data"][0]["countall"];
          this.data = response["data"][0]["data"]; 
          this.USER_ID = response["data"][0]["user"];
          this.nextrow = response["data"][0]["nextrow"];
          this.lastdebut = response["data"][0]["lastdebut"];
          this.prevrow = response["data"][0]["prevrow"];
        console.log("data", JSON.stringify(this.filtre)); 
      
      })
      }

    
    },
computed: {
    API_URL () {
      return store.state.API_URL
    }
  },
    async beforeMount()
    {
      this.getpubdemandes();
      this.USER_ID = await getUser();
    },
    mounted(){
      this.getpubdemandes();
      this.getDemandeurs();
      this.getUserLangue();
    }
}

</script>