<!-- eslint-disable vue/require-v-for-key -->
<template>
    <section>
    

<div class="accordion" id="filterAccordion">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Filtre
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#filterAccordion">
      <div class="accordion-body" style="padding: 0px; overflow: hidden;">
        
<div class="row" style="margin: 15px;">
    <div class="col-md-2 col-xs-12">Référence <input type="text" class="form-control" v-model="f_ref"/></div>
    <div class="col-md-4 col-xs-12"> Publication
        <div class="row">
                <div class="col-6">Du : <input type="date" class="form-control" v-model="f_dated"/></div>
                <div class="col-6">Au : <input type="date" class="form-control" v-model="f_datef"/></div>
        </div>
    
    </div>
    <div class="col-md-3 col-xs-12">Description <input type="text" class="form-control" v-model="f_desc"/></div>
    
    <div class="col-md-3 col-xs-12"> 

      <b-field label="Propriétaire" v-if="$store.state.USERID > 0">
            <b-select placeholder="propriétiare" v-model="f_prop">
            <option value="0"> Propriértiare </option>
                <option
                    v-for="demandeur in demandeurs"
                    :value="demandeur.id"
                    :key="demandeur.id">
                    {{ demandeur.rs }}
                </option>
            </b-select>
        </b-field>
    
    </div>

</div>

<div class="row" style="margin-top: 25px; padding: 15px 0px;  background: #fbfbfb;">
    <div class="col-4 text-center" @click="$emit('filtrer', f_ref, f_dated, f_datef, f_desc, f_prop)"><i class="fa-solid fa-magnifying-glass" style="color:green"></i> Filtrer</div>
    <div class="col-4 text-center"><i class="fa-solid fa-rotate-right" style="color:blue"></i> Réinitialiser</div>
    <div class="col-4 text-center" data-bs-toggle="collapse" href="#collapseOne" role="button" aria-expanded="false" ><i class="fa-solid fa-circle-xmark" style="color:red"></i> Fermer</div>
</div>


      </div>
    </div>
  </div>


</div>

    </section>
</template>

<script>


export default {
// eslint-disable-next-line vue/multi-word-component-names
name : 'Filterform',
data()  {
    return {
        f_dated : "",
        f_datef : "",
        f_ref:"",
        f_desc:"",
        f_prop:0
    }
},
props: {
    user:Number,
    demandeurs : []  
  }
}
</script>

<style>
.collapse {     margin-bottom: 25px; }
</style>