import axios from "axios";
import { store } from './store'

const add = (a, b) => {
    return a + b;
  };


async function getUser()
{
  await axios.get(store.state.API_URL+'getuser', { withCredentials:true}).then((response)=>{
    store.commit('setUserId', response["data"]);
    return response["data"];
  });
  
  
}

export { getUser, add };