<template>
    <section>
        <!-- Default -->
        <b-breadcrumb size="is-small" style="margin-bottom: 0px !important;
    padding-top: 15px;
    background: #f1f1f1;
    border-bottom: solid thin #0f7699;">
            <b-breadcrumb-item tag="router-link" to="/"> {{ $store.state.HOME }} </b-breadcrumb-item>
            <b-breadcrumb-item v-if="$store.state.PAGE == 1 " active> {{ $store.state.DEMANDES_PUBLIEES }}  </b-breadcrumb-item>
            <b-breadcrumb-item  v-if="$store.state.PAGE == 2"> {{ $store.state.MES_DEMANDES }} </b-breadcrumb-item>
            <b-breadcrumb-item  v-if="$store.state.PAGE == 3"> {{ $store.state.MES_OFFRES }} </b-breadcrumb-item>           
        </b-breadcrumb>
        </section>
</template>

<script>
export default {   
    // eslint-disable-next-line vue/multi-word-component-names
name : 'Breadcrumb',
}
</script>