<template>

    <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-4">
                <Loginform/>
                </div>
            </div>
    </div>


</template>

<script>
import  Loginform  from '../components/Loginform.vue'
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
    name: "Loginpage",
    components: {
        Loginform
    }
    }
</script>